import React from "react"
import { inject, observer } from "mobx-react"
import { Button, CardContent, Divider } from "@material-ui/core"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import CartItem from "../components/cart-item"
import { Link } from "gatsby"
import Box from "@material-ui/core/Box"

const grayText = "#979a9d"

const PageLayout = styled.div`
  display: grid;
  font-family: Helvetica;
  width: 100%;
  margin-top: 160px;
`

const CartContent = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 5fr 3fr;
  max-width: 1200px;
  margin-top: 16px;
  h2 {
    font: 500 20px/1 "Poppins", sans-serif;
    color: #374048;
    margin: 0;
  }

  @media only screen and (max-width: 860px) {
    grid-template-columns: 1fr;
  }
`

const CartSubheader = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  color: ${grayText};
`

const OrderSummary = styled.div`
  color: ${grayText};
`
const OrderSummaryHeader = styled.div`
  font: 700 14px/1.5 "Poppins", sans-serif;
  text-transform: uppercase;
  color: #374048;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`
const CartItemSection = styled.div`
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 16px;
  color: ${grayText};
`
const CheckoutButtonContainer = styled.div`
  margin-top: 32px;
`

const OrderLineItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 14px 0px;
`

const CartPage = ({ cart: cartStore }) => {
  const { cart, cartTotal, cartCount } = cartStore
  return (
    <Layout handleCartClick={() => {}}>
      <SEO title="Home" />
      <PageLayout>
        <CartContent>
          <CartItemSection>
            <OrderSummaryHeader>
              <span>Cart</span>
              <span>{cartCount} Items</span>
            </OrderSummaryHeader>
            <Divider orientation="horizontal" variant="fullWidth" light />
            {cart.map((item, i) => (
              <CartItem item={item} key={`${i}-${item.color}`} />
            ))}
            {!cart.length && (
              <Box
                css={{ height: "250px" }}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <h2 style={{ marginBottom: "8px" }}>Your Cart is Empty</h2>
                <div>
                  <Link to="/lpd-product-detail">Back To Products</Link>
                </div>
              </Box>
            )}
          </CartItemSection>
          <OrderSummary>
            <CardContent>
              <OrderSummaryHeader>Order Summary</OrderSummaryHeader>
              <Divider orientation="horizontal" variant="fullWidth" light />
              {cart.map((item, i) => (
                <OrderLineItem key={`${i}-${item.name}-${item.color}`}>
                  <CartSubheader>
                    {item.name} - ({item.color}) X {item.qty}{" "}
                  </CartSubheader>
                  {item.displayTotal}
                </OrderLineItem>
              ))}
              <OrderLineItem>
                <CartSubheader>Shipping - (est. @ checkout)</CartSubheader>
                $0.00
              </OrderLineItem>
              <OrderLineItem>
                <CartSubheader>Tax - (7.12%)</CartSubheader>
                $0.00
              </OrderLineItem>
              <Divider orientation="horizontal" variant="fullWidth" light />
              <OrderLineItem>
                <CartSubheader>Total Cost </CartSubheader> {cartTotal}
              </OrderLineItem>
            </CardContent>
            {cart.length > 0 && (
              <CheckoutButtonContainer>
                <Link to="/checkout">
                  <Button fullWidth variant="contained" color="primary">
                    Checkout
                  </Button>
                </Link>
              </CheckoutButtonContainer>
            )}
          </OrderSummary>
        </CartContent>
      </PageLayout>
    </Layout>
  )
}

export default inject("cart")(observer(CartPage))
